@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&display=swap');

@font-face {
  font-family: 'FreeSans';
  src: local('FreeSans'), url(./fonts/FreeSans/FreeSans.ttf) format('truetype');
}

* {
  padding: 0;
  margin: 0;
  /* font-family: 'IBM Plex Sans', sans-serif; */
  /* font-family: 'Montserrat', sans-serif; */
  font-family: 'Inter', sans-serif;
  -webkit-tap-highlight-color: transparent;
}

button label {
  cursor: pointer;
}

*::-webkit-scrollbar {
  width: 0.8em;
}

input,
textarea,
.searchContainer::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(80, 80, 80, 0.3);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--theme-secondary);
  outline: none;
}

/* editor js scroll */
.ce-popover__items::-webkit-scrollbar {
  width: 6px;
}

Link:hover,
Button:hover,
a:hover {
  opacity: 0.7;
}

.defaultInputRounded .MuiOutlinedInput-root {
  border-radius: 18px;
}

.x-panel-ghost {
  opacity: 1.0;
  -moz-opacity: 1.0;
  filter: alpha(opacity=100);
}