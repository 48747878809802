.noteTitleEditor, .noteTitleEditor span {
  font-family: FreeSans, sans-serif !important;
  letter-spacing: 1.5px;
}

.noteTitleEditor[placeholder]:empty:before {
  content: attr(placeholder);
  position: absolute;
  color: gray;
  background-color: transparent;
  font-family: FreeSans, sans-serif !important;
}

::selection{
	background-color: var(--theme-selector);
  color: var(--theme-selector-text);
}

.ce-block__content, 
.ce-toolbar__content {
 max-width: 100%;  /* example value, adjust for your own use case */
}

.noteEditorTitle,
.codex-editor__redactor, 
.codex-editor {
 height: 100%;  /* example value, adjust for your own use case */
}

.codex-editor svg{
  color: var(--theme-sidebar-text) !important;
}

.ce-header{
  font-size: 25px;
  font-weight: bold;
}

.cdx-checklist__item--checked .cdx-checklist__item-text{
  text-decoration: line-through !important;
  color: var(--theme-sidebar-text) !important;
}

.cdx-search-field__input{
  width: 100% !important;
}

@media only screen and (max-width: 770px) and (min-width: 650px) {
  .noteEditorTitle,
  .ce-block__content, 
  .ce-toolbar__content {
   max-width: 85%;  /* example value, adjust for your own use case */
  }}