.lastModifiedContainer::-webkit-scrollbar {
  height: 10px;
}
 
.lastModifiedContainer::-webkit-scrollbar-track {
  border-radius: 10px;
}
 
.lastModifiedContainer::-webkit-scrollbar-thumb {
  border-radius: 10px;
}