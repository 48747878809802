.button-wrapper button {
    z-index: 1 !important;
    height: 40px !important;
    max-width: 200px !important;
    margin: 10px !important;
    padding: 5px !important;
}
.excalidraw .App-menu_top .buttonList {
    display: flex !important;
}
.excalidraw-wrapper {
    height: 800px !important;
    margin: 50px !important;
    position: relative !important;
    overflow: hidden !important;
}
:root[dir="ltr"] .excalidraw .layer-ui__wrapper .zen-mode-transition.App-menu_bottom--transition-left {
    transform: none !important;
}
.excalidraw .panelColumn {
    text-align: left !important;
}
.export-wrapper {
    display: flex !important;
    flex-direction: column !important;
    margin: 50px !important;
}
.export-wrapper__checkbox {
    display: flex !important; 
}
.excalidraw {
    --color-primary: var(--theme-secondary) !important;
    --color-primary-darker: var(--theme-secondary-text) !important;
    --color-primary-darkest: var(--theme-secondary) !important;
    --color-primary-light: var(--theme-secondary) !important;
}
.excalidraw button.custom-element {
    width: 2rem !important;
    height: 2rem !important;
    margin: 0 8px !important;
}
.excalidraw .custom-footer, .excalidraw .custom-element {
    padding: 0.1rem !important;
}


@media only screen and (max-width: 1500px){
    .library-button__label{
        display: none !important;
    }
}

@media only screen and (max-width: 550px){
    .excalidraw .Stack{
        display: block !important;
    }

    .library-button{
        margin-top: 10px;
    }
}