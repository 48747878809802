.buttonColor {
    width: 30px;
    height: 30px;
    border-radius: 4px;
    box-sizing: border-box;
    border: 1px solid #ddd;
}

.buttonsColorMenuContainer {
    display: grid;
    padding: 0.5rem;
    grid-template-columns: repeat(5, auto);
    grid-gap: 0.5rem;
}