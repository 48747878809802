.mainContainer{
    position: fixed;
    top: 50px;
    bottom: 0;
    right: 0;
    overflow: auto;
    word-break: break-word;
}

.iconSidebarBody{
    left: 0px;
}

.fullSidebarBody{
    left: 265px;
}

@media(max-width:768px){

    .fullSidebarBody, .iconSidebarBody{
        left: 0px
    }
}