/* SIDEBAR STYLES */
.sidebarContainer{
    position: fixed;
    left: 0;
    background-color: var(--theme-sidebar);
    color: var(--theme-sidebar-text);
    z-index: 3;
    top: 0;
    transition: 0.5s;
    overflow: auto;
}

.sidebarContainer::-webkit-scrollbar {
    width: 6px;
  }
   
.sidebarContainer::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(80, 80, 80, 0.3);
}

.sidebarContainerIcons{
    padding: 0;
    width: 75px;
    background-color: var(--theme-primary);
    background-color: var(--theme-primary-text);
}

.sidebarContainerFull{
    width: 265px;
}


/* FIRST SIDEBAR BOX */

.firstContainersFull{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    padding: 0 1.25rem;
}

.firstContainersIcon{
    display: flex;
    justify-content: center;
    align-items: center;
}

.titleLogo{
    max-width: 150px;
}

.firstContainersFull .titleLogo, .titleLogo{
    display: flex;
}

.firstContainersIcon .titleLogo{
    display: none;
}

/* OPTIONS BOXES */

.iconsContainersIcon{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 0;
    border-radius: 5px;
    margin: 5px auto;
    width: 85%;
}

.iconsContainersFull .textOption{
    display: block;
    font-size: 15px;
}

.iconsContainersIcon .textOption {
    display: none;
}

.iconsContainersFull{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left:10px;
    padding-right:10px;
    border-radius: 5px;
    margin: 2px auto;
    width: 100%;
    gap: 10px;
    height: 30px;
}

.directoryButtonFull{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 3px;
    width: 100%;
    gap: 10px;
    height: 30px;
    padding: 0 0px;
    width: 100%;
}



.directoryContainerFull{
    padding: 0 3px;
    margin: 2px auto;
    margin-left: 5px;
    position: relative;
}

.directoryContainerFullOpened{
    position: relative;
    padding: 0 3px;
    margin: 2px auto;
    margin-left: 5px;
}

.buttonContainerFull{
    padding: 0 3px;
    margin: 2px auto;
    margin-left: 5px;
}

.noteButtonFull{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 3px;
    gap: 10px;
    height: 30px;
    padding: 0 7px;
    width: 100%;
    margin: 2px auto;
    margin-left: 5px;
}


.bottomButtonContainerFull{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 0px;
    width: 100%;
    gap: 10px;
    padding-left:10px;
    padding-right:10px;
    height: 35px;
}

.directoryContainerIcon {
    display: none;
}


.iconsContainersFull:hover,.iconsContainersIcon:hover, .removeButton:hover, .bottomButtonContainerFull:hover,.noteOpened,.noteContainerFullOpened{
    background-color: #E8E7E4;
    color: black;
}

.lastOpened{
    background-color: #f1f0ef;
    color: black;
}


.opened{
    color: black;
}

.sidebarContainerFull .iconHiddenOnFull{
    display: none !important;
}

.backdropMobile{
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 2;
    display: none;
}

.verticalLine{
    border-left: 1px solid lightgray;
    top:30px;
    left: 7px;
    bottom: 0;
    position: absolute;
}

.buttonActionsIcons{
    width: 26px;
    min-width: 26px;
    display: flex;
    justify-content: flex-end;
}

@media(max-width:1023px){

    .sidebarContainerIcons{
        left: -60%;
        width: 0;
    }

    .sidebarContainerFull{
        width: 80%;
        min-width: 230px;
    }

    .backdropMobile{
        display: block;
    }

    .directoryButtonFull{
        height: 33px;
    }

    .verticalLine{
        top:33px;
    }
}
