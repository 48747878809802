.link:hover{
    opacity: 1 !important;
}
.dragSortActive {
    background: transparent;
    color: transparent;
    border: 2px solid var(--theme-secondary);
}

.grabbing:active {
}