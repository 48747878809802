.searchIcon{
    transition: 0.3s;
}

.notShowingIcon{
    width: 0;
}

.showingIcon{
    width: 16px;
}