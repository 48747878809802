.navbarContainer{
    justify-content: space-between;
    align-items: center;
    background-color: white;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    transition: 0.5s;
}

.userImage{
    border-radius: 8px;
    width: 40px;
    height: 40px;
}

.logoImage{
    max-width: 100px;
    max-height: 20px;
}

@media(max-width:1023px){

    .navbarContainer{
        left: 0px !important;
    }
}
